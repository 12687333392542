import React from 'react'
import FormLabel from '@mui/material/FormLabel'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import ToggleButton from '@mui/material/ToggleButton'
import { useBreakPoints, useLOCValues } from '../../lib/hooks'
import ErrorMessage from './ErrorMessage'
import { isNumber } from '@/lib/utils/math'

type LOCLabels =
    | { name: 'locNow'; label: 'Now' }
    | { name: 'locBullseye'; label: 'Bull' }
    | { name: 'locHigh'; label: 'High' }
    | { name: 'locLow'; label: 'Low' }
type LOCNames = LOCLabels['name']
type LOCProps = LOCLabels & {
    touched: { [key in LOCNames]?: boolean }
    errors: { [key in LOCNames]?: string | string[] }
    values: { [key in LOCNames]?: number | '' | null | undefined }
    handleChange: (value: string | undefined) => void
    required?: boolean
}

const LOC = ({ name, label, touched, errors, values, handleChange, required }: LOCProps): JSX.Element => {
    const { data: locValues } = useLOCValues()
    const { isMobile } = useBreakPoints()

    return (
        <>
            <FormLabel
                required={required}
                sx={{
                    display: 'block',
                    '.MuiFormLabel-asterisk': {
                        position: 'absolute',
                        top: 0,
                        color: touched[name] && !!errors[name] ? 'error.main' : '',
                    },
                }}
                id={name}
            >
                {label}
            </FormLabel>
            <ToggleButtonGroup
                value={values[name]?.toString()}
                exclusive
                onChange={(_, value: string) => {
                    const matchingValue = value === values[name]?.toString()
                    if (matchingValue) handleChange(undefined)
                    handleChange(value)
                }}
                color="primary"
                orientation={isMobile ? 'vertical' : 'horizontal'}
            >
                {locValues?.map((loc) => (
                    <ToggleButton
                        value={loc.value.toString()}
                        key={loc.value}
                        name={name}
                        sx={{ width: { xs: 120, sm: 50 } }}
                    >
                        {loc.abbr}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
            {touched[name] && !!errors[name] && (
                <ErrorMessage text={isNumber(values[name]) ? errors[name] : `LOC ${label} is required`} />
            )}
        </>
    )
}

export default LOC
