import type { RequiredReportFormProps } from './RequiredReportForm'
import React, { useCallback } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import { DialogContent, DialogContentText } from '@mui/material'
import dayjs from 'dayjs'
import RequiredReportForm from './RequiredReportForm'

type RequiredReportModalProps = RequiredReportFormProps & {
    open: boolean
    setOpen: (player: Partial<DTO.BoardPlayer> | null) => void
}

const RequiredReportModal = ({ open, setOpen, ...formProps }: RequiredReportModalProps): JSX.Element => {
    const onClose = useCallback(() => setOpen(null), [setOpen])
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Mark Assignment as Not Required</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {formProps.player?.name ? formProps.player.name : ''} | {formProps.assignment.name} |{' '}
                    {dayjs(formProps.assignment.date).format('MM/DD/YYYY')}
                </DialogContentText>
            </DialogContent>
            {open && <RequiredReportForm {...formProps} />}
        </Dialog>
    )
}

export default RequiredReportModal
