import type TableConfig from '../../lib/types/tableConfigTypes'
import type { TableConfigField } from '../../lib/types/tableConfigTypes'
import React from 'react'
import PostAddIcon from '@mui/icons-material/PostAdd'
import IconButton from '@mui/material/IconButton'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Typography from '@mui/material/Typography'
import { MenuItem } from '@mui/material'
import MenuItemLink from '../MenuItemLink'

import { boxscoreFields } from '../form/PlayerGameBoxscore'
import Link from '../Link'
import Controls from '../Controls'

const isMobile = () => window.matchMedia('(max-width: 600px)').matches

export type BoardPlayerWithBoxScore = DTO.GameBoardPlayer &
    Partial<Omit<DTO.GameBoxscore, 'teamId' | 'playerId'>> & {
        assignmentId: string
        state: DTO.IncompleteReport['state']
    }

const getTableFieldsForEvent = (
    isGame: boolean,
    showRowHighlighting: boolean,
    setPlayer: (player: Partial<DTO.BoardPlayer>) => void,
    setOpenLOCDialog: (id: string) => void,
    setOpenSkillDialog: (id: string) => void,
    showControls: boolean,
    showTiers: boolean
): TableConfigField<BoardPlayerWithBoxScore>[] => {
    const fields: TableConfigField<BoardPlayerWithBoxScore>[] = [
        {
            key: 'fullName',
            label: 'Name',
            select: (x) => (
                <>
                    <Link href={`/players/${x.urlSlug}/feed`} {...{ target: '_blank', rel: 'noopener noreferrer' }}>
                        {isMobile() ? `${x.fullName[0]}. ${x.lastName}` : x.fullName}
                    </Link>
                    <Typography
                        sx={{ position: 'relative', marginLeft: '4px', color: 'text.secondary' }}
                        component="span"
                        variant="caption"
                    >
                        {x.position}
                    </Typography>
                </>
            ),
            format: {
                whiteSpace: 'nowrap',
                maxWidth: { xs: 125, sm: 175 },
                minWidth: { xs: 125, sm: 175 },
                width: { xs: 125, sm: 'initial' },
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                position: 'sticky',
                left: 0,
                backgroundColor: 'white',
                borderRight: isMobile() ? '1px solid' : 'none',
                borderRightColor: 'divider',
                zIndex: 1,
            },
            headCellFormat: {
                position: 'sticky',
                left: 0,
                backgroundColor: 'white',
                borderRight: isMobile() ? '1px solid' : 'none',
                borderRightColor: 'divider',
                zIndex: 1,
            },
            skeletonStyle: { xs: 150 },
        },
    ]

    if (showTiers) {
        fields.push({
            key: 'boardTiers',
            label: 'Tiers',
            skeletonStyle: 150,
            format: {
                whiteSpace: 'nowrap',
                maxWidth: 150,
                minWidth: { xs: 125, sm: 150 },
                width: { xs: 125, sm: 'initial' },
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                position: { xs: 'initial', sm: 'sticky' },
                left: 175,
                backgroundColor: 'white',
                borderRight: isGame && !isMobile() ? '1px solid' : 'none',
                borderRightColor: 'divider',
                zIndex: 1,
            },
            headCellFormat: {
                position: { xs: 'inital', sm: 'sticky' },
                left: 175,
                backgroundColor: 'white',
                borderRight: isGame && !isMobile() ? '1px solid' : 'none',
                borderRightColor: 'divider',
                zIndex: 1,
            },
            select: (x) => {
                if (x.boardName && x.boardTiers.length === 0) {
                    return x.boardName
                }
                return x.boardTiers.map((bt) => bt).join(', ')
            },
        })
    }

    if (isGame && !isMobile()) {
        fields.push(
            ...(boxscoreFields().filter(
                (f) => f.key !== 'gameName' && f.key !== 'gp' && f.key !== 'gs'
            ) as unknown as TableConfigField<BoardPlayerWithBoxScore>[])
        )
    }

    if (showControls) {
        fields.push({
            key: 'createReportButton',
            label: '',
            format: {
                position: isGame || isMobile() ? 'sticky' : 'relative',
                right: isGame || isMobile() ? 32 : 0,
                maxWidth: { xs: 40, sm: 'initial' },
                width: { xs: 40, sm: 'initial' },
                backgroundColor: 'white',
                padding: 0,
                textAlign: 'center',
            },
            select: (x: BoardPlayerWithBoxScore): JSX.Element => {
                const { urlSlug, gameId, assignmentId } = x
                const href = gameId
                    ? `/posts/create/scouting?playerid=${urlSlug}&gameid=${gameId}`
                    : `/posts/create/scouting?playerid=${urlSlug}&assignmentid=${assignmentId}`

                if (x.state === 'Complete' && showRowHighlighting)
                    return (
                        <CheckCircleIcon
                            sx={{ transform: 'translate(-50%, -50%)', position: 'absolute' }}
                            color="success"
                        />
                    )

                return (
                    <Link href={href} {...{ target: '_blank', rel: 'noopener noreferrer' }}>
                        <IconButton size="small" aria-label="create report" aria-haspopup="true" edge="end">
                            <PostAddIcon />
                        </IconButton>
                    </Link>
                )
            },
            skeletonStyle: 35,
        })

        fields.push({
            key: 'requiredReportButton',
            label: '',
            format: {
                position: isGame || isMobile() ? 'sticky' : 'relative',
                backgroundColor: 'white',
                maxWidth: { xs: 40, sm: 'initial' },
                width: { xs: 40, sm: 'initial' },
                textAlign: 'center',
                paddingY: 0,
                padding: 0,
                right: 0,
            },
            select: (x: BoardPlayerWithBoxScore): JSX.Element => (
                <Controls padding="4px">
                    <MenuItemLink
                        href={
                            x.gameId
                                ? `/posts/create/scouting?playerid=${x.urlSlug}&gameid=${x.gameId}`
                                : `/posts/create/scouting?playerid=${x.urlSlug}&assignmentid=${x.assignmentId}`
                        }
                        label="Create Scouting Report"
                        authorize
                        newTab
                    />
                    <MenuItemLink
                        href={`/posts/create/intel?playerid=${x.urlSlug}`}
                        label="Create Intel Report"
                        authorize
                        newTab
                    />
                    <MenuItem onClick={() => setOpenLOCDialog(x.playerId)}>Add LOCs</MenuItem>
                    <MenuItem onClick={() => setOpenSkillDialog(x.playerId)}>Add Skills</MenuItem>
                    {(x.state === 'Incomplete - Active' || x.state === 'Incomplete - Inactive') &&
                    showRowHighlighting ? (
                        <MenuItem onClick={() => setPlayer({ name: x.fullName, id: x.playerId })}>
                            Mark as Not Requried
                        </MenuItem>
                    ) : (
                        <>{}</>
                    )}
                </Controls>
            ),
            skeletonStyle: 35,
        })
    }

    return fields
}

const tableConfig = (
    isGame: boolean,
    showRowHighlighting: boolean,
    setPlayer: (player: Partial<DTO.BoardPlayer>) => void,
    setOpenLOCDialog: (id: string) => void,
    setOpenSkillDialog: (id: string) => void,
    showControls: boolean,
    showTiers: boolean
): TableConfig<BoardPlayerWithBoxScore> => ({
    loadingSkeleton: {
        height: 600,
        numOfRows: 17,
    },
    fields: getTableFieldsForEvent(
        isGame,
        showRowHighlighting,
        setPlayer,
        setOpenLOCDialog,
        setOpenSkillDialog,
        showControls,
        showTiers
    ),
})

export default tableConfig
