import React from 'react'
import FormLabel from '@mui/material/FormLabel'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import ToggleButton from '@mui/material/ToggleButton'
import { useBreakPoints } from '../../lib/hooks'
import ErrorMessage from './ErrorMessage'

type SkillNames =
    | 'athleticism'
    | 'bbiq'
    | 'shooter'
    | 'shotCreator'
    | 'quickDecisions'
    | 'scorer'
    | 'finisher'
    | 'playMaker'
    | 'rimProtector'
    | 'teamDefender'
    | 'individualDefense'
    | 'pnrDefender'
    | 'rebounder'
    | 'competitiveness'
type SkillProps = {
    name: SkillNames
    label: string
    touched: { [key in SkillNames]?: boolean }
    errors: { [key in SkillNames]?: string | string[] }
    values: { [key in SkillNames]?: number | '' | null | undefined }
    handleChange: (value: string | undefined) => void
    required: boolean
}

const Skill = ({ name, label, touched, errors, values, handleChange, required }: SkillProps): JSX.Element => {
    const { isMobile } = useBreakPoints()
    return (
        <>
            <FormLabel
                required={required}
                sx={{
                    display: 'block',
                    '.MuiFormLabel-asterisk': {
                        position: 'absolute',
                        top: 0,
                        color: touched[name] && Boolean(errors[name]) ? 'error.main' : '',
                    },
                }}
                id={name}
            >
                {label}
            </FormLabel>
            <ToggleButtonGroup
                value={values[name]?.toString()}
                exclusive
                onChange={(_, value: string) => {
                    const matchingValue = value === values[name]?.toString()
                    if (matchingValue) handleChange(undefined)
                    handleChange(value)
                }}
                color="primary"
                orientation={isMobile ? 'vertical' : 'horizontal'}
            >
                {new Array(11).fill(undefined).map((_, t) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <ToggleButton value={t.toString()} key={t} name={name} sx={{ width: { xs: 120, sm: 41 } }}>
                        {t}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
            {touched[name] && Boolean(errors[name]) && <ErrorMessage text={`${label} is required`} />}
        </>
    )
}

export default Skill
