import type TableConfig from '../../lib/types/tableConfigTypes'
import React from 'react'
import Typography from '@mui/material/Typography'
import Link from '../Link'

const isMobile = () => window.matchMedia('(max-width: 600px)').matches

const tableConfig = (showTiers: boolean): TableConfig<DTO.GameBoardPlayer> => {
    const fields: TableConfig<DTO.GameBoardPlayer>['fields'] = [
        {
            key: 'lastName',
            label: 'PLAYER',
            sortColumn: ['lastName', 'firstName'],
            skeletonStyle: 100,
            format: { whiteSpace: 'nowrap', maxWidth: { xs: 125, md: 'initial' } },
            select: (p) => (
                <>
                    <Link
                        sx={{ maxWidth: { xs: 125, md: 'initial' }, overflow: 'hidden', textOverflow: 'ellipsis' }}
                        href={`/players/${p.urlSlug}/feed`}
                        {...{ target: '_blank', rel: 'noopener noreferrer' }}
                    >
                        {isMobile() ? `${p.fullName[0]}. ${p.lastName}` : p.fullName}
                    </Link>
                    <Typography
                        sx={{ position: 'relative', marginLeft: '4px', color: 'text.secondary' }}
                        component="span"
                        variant="caption"
                    >
                        {p.jerseyNum}
                    </Typography>
                </>
            ),
        },
    ]

    if (showTiers) {
        fields.push({
            key: 'boardTiers',
            label: 'Tiers',
            skeletonStyle: 100,
            format: {
                maxWidth: { xs: 125, md: 'initial' },
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
            },
            select: (p: DTO.GameBoardPlayer): string => {
                if (p.boardName && p.boardTiers.length === 0) {
                    return p.boardName
                }

                return p.boardTiers.map((bt) => bt).join(', ')
            },
        })
    }

    fields.push({
        key: 'position',
        label: 'POS',
        sortColumn: 'positionNumber',
        skeletonStyle: 25,
        select: (p) => p.position,
    })

    return {
        loadingSkeleton: {
            height: 600,
            numOfRows: 17,
        },
        fields,
    }
}

export default tableConfig
