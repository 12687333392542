import type { SelectChangeEvent } from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import React from 'react'
import MenuItem from '@mui/material/MenuItem'

type SelectLevelProps = {
    onChange: (e: SelectChangeEvent<Enum.BoardLevels>) => void
    value: Enum.BoardLevels
}

const SelectLevel = ({ onChange, value }: SelectLevelProps): JSX.Element => {
    const formLevels = ['Pro', 'Amateur']
    return (
        <FormControl fullWidth size="small">
            <InputLabel id="level-label">Scouting Group</InputLabel>
            <Select labelId="level-label" label="Scouting Group" name="level" onChange={onChange} value={value}>
                {formLevels.map((l) => (
                    <MenuItem key={l} value={l}>
                        {l}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default SelectLevel
