import type { HandleSubmit } from '../../lib/types/formDialogTypes'
import type { SetStateAction } from 'react'
import type { CreateSkills } from '../../lib/hooks/useBoard'
import React from 'react'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { useFormik } from 'formik'
import * as yup from 'yup'
import Grid from '@mui/material/Grid'
import { useSession } from 'next-auth/react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import useToastContext from '../../lib/hooks/useToastContext'
import { useCreateSkillsForBoardPlayer } from '../../lib/hooks'
import { isSubmitButtonDisabled } from '../form/formUtils'
import Skill from '../form/Skill'

type SkillDialogProps = {
    open: boolean
    setOpen: (open: SetStateAction<string | undefined>) => void
    name: string
    playerId: string
    boardLevel: Enum.BoardLevels
}

const SkillDialog = ({ open, setOpen, name, playerId, boardLevel }: SkillDialogProps): JSX.Element => {
    const toastContext = useToastContext()
    const { data: session } = useSession()
    const createSkills = useCreateSkillsForBoardPlayer()

    const handleCreateSkills = (values: CreateSkills, { resetForm }: HandleSubmit<CreateSkills>) =>
        createSkills.mutateAsync(values, {
            onSuccess: () => {
                toastContext?.addToast({ severity: 'success', message: `Created Skills for ${name}` })
                setOpen(undefined)
                resetForm()
            },
            onError: (error) => {
                toastContext?.addToast({ severity: 'error', message: error.message })
            },
        })

    const handleSubmit = (values: CreateSkills, { resetForm }: HandleSubmit<CreateSkills>) =>
        handleCreateSkills(values, { resetForm })

    const validationSchema = (bLevel: Enum.BoardLevels) =>
        yup.object({
            playerId: yup.string().trim().required('Player is required'),
            scoutEntityId: yup.string().trim().required('Scout is required'),
            scorer: yup.number().min(0).max(10).required(),
            shooter: yup.number().min(0).max(10).required(),
            finisher: yup.number().min(0).max(10).required(),
            shotCreator: yup.number().min(0).max(10).required(),
            playMaker: yup.number().min(0).max(10).required(),
            quickDecisions: yup.number().min(0).max(10).required(),
            pnrDefender:
                bLevel === 'Pro' ? yup.number().min(0).max(10).required() : yup.number().min(0).max(10).nullable(),
            rimProtector: yup.number().min(0).max(10).required(),
            teamDefender: yup.number().min(0).max(10).required(),
            individualDefense: yup.number().min(0).max(10).required(),
            athleticism: yup.number().min(0).max(10).required(),
            bbiq: yup.number().min(0).max(10).required(),
            rebounder: yup.number().min(0).max(10).required(),
            competitiveness: yup.number().min(0).max(10).required(),
        })

    const formik = useFormik<CreateSkills>({
        initialValues: {
            athleticism: null,
            bbiq: null,
            shooter: null,
            shotCreator: null,
            quickDecisions: null,
            scorer: null,
            finisher: null,
            playMaker: null,
            rimProtector: null,
            teamDefender: null,
            individualDefense: null,
            pnrDefender: null,
            rebounder: null,
            competitiveness: null,
            playerId,
            scoutEntityId: session?.entityId as string,
        },
        validationSchema: () => validationSchema(boardLevel),
        onSubmit: async (values, { resetForm }) => {
            await handleSubmit(values, { resetForm })
        },
        enableReinitialize: true,
    })

    return (
        <Dialog maxWidth="xl" open={open} onClose={() => setOpen(undefined)}>
            <Box
                component="form"
                noValidate
                onSubmit={formik.handleSubmit}
                sx={{ display: 'flex', flexDirection: 'column', overflowY: 'auto' }}
            >
                <DialogTitle
                    sx={{
                        display: 'flex',
                        alignItems: 'baseline',
                        gap: 0.7,
                        flexWrap: 'wrap',
                        paddingY: 1,
                    }}
                >
                    <Typography variant="h6" fontWeight="normal">
                        Add Skills for
                    </Typography>
                    {name}
                </DialogTitle>
                <DialogContent dividers>
                    <Grid xs={12} item container rowSpacing={1} columnSpacing={2}>
                        <Grid xs={12} item>
                            <Typography variant="subtitle2" fontSize="16px">
                                Offense
                            </Typography>
                        </Grid>

                        <Grid xs={6} sm={12} lg={6} xl={4} item>
                            <Skill
                                name="scorer"
                                label="Scorer"
                                touched={formik.touched}
                                errors={formik.errors}
                                values={formik.values}
                                handleChange={(val) => formik.setFieldValue('scorer', val)}
                                required
                            />
                        </Grid>
                        <Grid xs={6} sm={12} lg={6} xl={4} item>
                            <Skill
                                name="shooter"
                                label="Shooter"
                                touched={formik.touched}
                                errors={formik.errors}
                                values={formik.values}
                                handleChange={(val) => formik.setFieldValue('shooter', val)}
                                required
                            />
                        </Grid>
                        <Grid xs={6} sm={12} lg={6} xl={4} item>
                            <Skill
                                name="finisher"
                                label="Finisher"
                                touched={formik.touched}
                                errors={formik.errors}
                                values={formik.values}
                                handleChange={(val) => formik.setFieldValue('finisher', val)}
                                required
                            />
                        </Grid>
                        <Grid xs={6} sm={12} lg={6} xl={4} item>
                            <Skill
                                name="shotCreator"
                                label="Shot Creator"
                                touched={formik.touched}
                                errors={formik.errors}
                                values={formik.values}
                                handleChange={(val) => formik.setFieldValue('shotCreator', val)}
                                required
                            />
                        </Grid>
                        <Grid xs={6} sm={12} lg={6} xl={4} item>
                            <Skill
                                name="playMaker"
                                label="Play Maker"
                                touched={formik.touched}
                                errors={formik.errors}
                                values={formik.values}
                                handleChange={(val) => formik.setFieldValue('playMaker', val)}
                                required
                            />
                        </Grid>
                        <Grid xs={6} sm={12} lg={6} xl={4} item>
                            <Skill
                                name="quickDecisions"
                                label="Quick Decisions"
                                touched={formik.touched}
                                errors={formik.errors}
                                values={formik.values}
                                handleChange={(val) => formik.setFieldValue('quickDecisions', val)}
                                required
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <Typography variant="subtitle2" fontSize="16px">
                                Defense
                            </Typography>
                        </Grid>
                        {boardLevel === 'Pro' && (
                            <Grid xs={6} sm={12} lg={6} xl={4} item>
                                <Skill
                                    name="pnrDefender"
                                    label="PnR Defender"
                                    touched={formik.touched}
                                    errors={formik.errors}
                                    values={formik.values}
                                    handleChange={(val) => formik.setFieldValue('pnrDefender', val)}
                                    required
                                />
                            </Grid>
                        )}
                        <Grid xs={6} sm={12} lg={6} xl={4} item>
                            <Skill
                                name="rimProtector"
                                label="Rim Protector"
                                touched={formik.touched}
                                errors={formik.errors}
                                values={formik.values}
                                handleChange={(val) => formik.setFieldValue('rimProtector', val)}
                                required
                            />
                        </Grid>
                        <Grid xs={6} sm={12} lg={6} xl={4} item>
                            <Skill
                                name="teamDefender"
                                label="Team Defender"
                                touched={formik.touched}
                                errors={formik.errors}
                                values={formik.values}
                                handleChange={(val) => formik.setFieldValue('teamDefender', val)}
                                required
                            />
                        </Grid>
                        <Grid xs={6} sm={12} lg={6} xl={4} item>
                            <Skill
                                name="individualDefense"
                                label="Individual Defense"
                                touched={formik.touched}
                                errors={formik.errors}
                                values={formik.values}
                                handleChange={(val) => formik.setFieldValue('individualDefense', val)}
                                required
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <Typography variant="subtitle2" fontSize="16px">
                                General
                            </Typography>
                        </Grid>
                        <Grid xs={6} sm={12} lg={6} xl={4} item>
                            <Skill
                                name="athleticism"
                                label="Athleticism"
                                touched={formik.touched}
                                errors={formik.errors}
                                values={formik.values}
                                handleChange={(val) => formik.setFieldValue('athleticism', val)}
                                required
                            />
                        </Grid>
                        <Grid xs={6} sm={12} lg={6} xl={4} item>
                            <Skill
                                name="bbiq"
                                label="BBIQ"
                                touched={formik.touched}
                                errors={formik.errors}
                                values={formik.values}
                                handleChange={(val) => formik.setFieldValue('bbiq', val)}
                                required
                            />
                        </Grid>
                        <Grid xs={6} sm={12} lg={6} xl={4} item>
                            <Skill
                                name="rebounder"
                                label="Rebounder"
                                touched={formik.touched}
                                errors={formik.errors}
                                values={formik.values}
                                handleChange={(val) => formik.setFieldValue('rebounder', val)}
                                required
                            />
                        </Grid>
                        <Grid xs={6} sm={12} lg={6} xl={4} item>
                            <Skill
                                name="competitiveness"
                                label="Competitiveness"
                                touched={formik.touched}
                                errors={formik.errors}
                                values={formik.values}
                                handleChange={(val) => formik.setFieldValue('competitiveness', val)}
                                required
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="text"
                        onClick={() => {
                            setOpen(undefined)
                            formik.resetForm()
                        }}
                        disabled={formik.isSubmitting}
                        size="large"
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        variant="contained"
                        type="submit"
                        size="large"
                        loading={formik.isSubmitting}
                        disabled={isSubmitButtonDisabled({
                            isSubmitting: formik.isSubmitting,
                            dirty: formik.dirty,
                            isValid: formik.isValid,
                            submitCount: formik.submitCount,
                        })}
                    >
                        Submit
                    </LoadingButton>
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default SkillDialog
