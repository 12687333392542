import type { SelectProps } from '@mui/material/Select'
import React, { useMemo } from 'react'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { useQueryScouts } from '../../lib/hooks/useScoutAssignments'

type SelectScoutProps = {
    onChange: SelectProps['onChange']
    value: string[]
    multiple?: boolean
    level?: Enum.BoardLevels
    filterUserEntityId?: string | undefined
}

const SelectScout = ({
    onChange,
    value,
    multiple = true,
    level,
    filterUserEntityId,
}: SelectScoutProps): JSX.Element => {
    const { data: scouts } = useQueryScouts()
    const filteredScouts = useMemo(
        () =>
            scouts?.filter(
                (s) =>
                    !level || (s.level?.includes(level) && (!filterUserEntityId || s.entityId === filterUserEntityId))
            ),
        [scouts, level, filterUserEntityId]
    )

    return (
        <FormControl fullWidth size="small">
            <InputLabel id="scouts-label">Scout(s)</InputLabel>
            <Select
                labelId="scouts-label"
                label={multiple ? 'Scout(s)' : 'Scout'}
                name="scouts"
                multiple={multiple}
                onChange={onChange}
                value={value}
            >
                {filteredScouts?.map((u) => (
                    <MenuItem key={u.entityId} value={u.entityId}>
                        {u.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default SelectScout
