import type { IncompleteReportsQueryParams, ScoutAssignmentQueryParams } from '../../lib/hooks/useScoutAssignments'
import React from 'react'
import * as yup from 'yup'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import TextField from '@mui/material/TextField'
import { useFormik } from 'formik'
import Grid from '@mui/material/Grid'

import { useAddUnrequiredReport, useBreakPoints } from '../../lib/hooks'
import handleError from '../../lib/utils/handleError'
import ErrorMessage from '../form/ErrorMessage'
import useToastContext from '../../lib/hooks/useToastContext'
import { isSubmitButtonDisabled } from '../form/formUtils'

const validationSchema = yup.object().shape({
    reason: yup.string().required(),
})

export type RequiredReportFormProps = {
    onClose: () => void
    incompleteReportParams: IncompleteReportsQueryParams
    filterParams: ScoutAssignmentQueryParams
    assignment: DTO.AssignmentCalendarExtendedProps
    player: DTO.BoardPlayer | null
}

const RequiredReportForm = ({
    onClose,
    incompleteReportParams,
    filterParams,
    assignment,
    player,
}: RequiredReportFormProps): JSX.Element => {
    const { mutate: submit } = useAddUnrequiredReport({ incompleteReportParams, filterParams })
    const toastContext = useToastContext()
    const { isMobile } = useBreakPoints()
    const assignmentIds = Array.from(new Set(assignment.scoutAssignments.map((d) => d.assignmentId)))
    const formik = useFormik<DTO.UnrequiredReport>({
        validationSchema,
        initialValues: {
            assignmentIds,
            playerId: player ? player.id : '',
            reason: '',
        },
        onSubmit: (values, { setSubmitting }) => {
            const submitValues = {
                assignmentIds: values.assignmentIds,
                playerId: values.playerId,
                reason: values.reason,
            }
            submit(submitValues, {
                onSuccess: () => {
                    toastContext?.addToast({
                        severity: 'success',
                        message: 'Report Not Required',
                    })
                    formik.resetForm()
                    onClose()
                },
                onError: (error) => handleError(error, toastContext, setSubmitting),
            })
        },
    })

    return (
        <form noValidate onSubmit={formik.handleSubmit}>
            <DialogContent sx={!isMobile ? { width: 600 } : {}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ paddingTop: '15px' }}>
                        <TextField
                            autoFocus
                            name="reason"
                            label="Reason"
                            size="small"
                            placeholder="Reason"
                            fullWidth
                            value={formik.values.reason}
                            onChange={formik.handleChange}
                            disabled={formik.isSubmitting}
                        />
                    </Grid>
                    {formik.touched.reason && Boolean(formik.errors.reason) && <ErrorMessage text="Enter a reason" />}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="text"
                    onClick={() => {
                        onClose()
                        formik.resetForm()
                    }}
                    disabled={formik.isSubmitting}
                >
                    Cancel
                </Button>
                <LoadingButton
                    variant="contained"
                    type="submit"
                    loading={formik.isSubmitting}
                    disabled={isSubmitButtonDisabled({
                        isSubmitting: formik.isSubmitting,
                        dirty: formik.dirty,
                        isValid: formik.isValid,
                        submitCount: formik.submitCount,
                    })}
                >
                    Mark Not Required
                </LoadingButton>
            </DialogActions>
        </form>
    )
}

export default RequiredReportForm
